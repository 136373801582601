/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Phone from 'img/phone.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Box = styled.div`
  background-color: #e9e9e9;

  & a {
    &:hover {
      text-decoration: underline;
    }
  }
`

interface MoreInformationProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_MoreInformation
}

const MoreInformation: React.FC<MoreInformationProps> = ({ fields }) => {
  const query = useStaticQuery<GatsbyTypes.moreInformationQuery>(graphql`
    query moreInformation {
      allWpVestiging {
        nodes {
          title
          acfFields {
            phone {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <section className="row justify-content-between py-lg-3 mb-4">
      <div className="col-lg-7 mb-lg-0 mb-3">
        <ParseContent content={fields.leftDescription || ''} />
      </div>

      <div className="col-lg-4 d-flex flex-column">
        <Box className="p-4">
          <span className="font-size-larger font-weight-bold">
            Meer informatie?
          </span>
          <div className="px-4">
            {query.allWpVestiging.nodes.map((n, index) => (
              <div className="d-flex py-2 align-items-center" key={index}>
                <Phone />
                <div className="ms-4">
                  <p className="mb-0 font-weight-bold">{n.title}</p>
                  <a href={n.acfFields?.phone?.url || '/'}>
                    {n.acfFields?.phone?.title}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </div>
    </section>
  )
}

export default MoreInformation
